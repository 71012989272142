/* styles.css */
input.style_input,
select.style_input {
  padding: 10px;
  border: 1px solid #2cda6d; /* Default border color */
  border-radius: 10px;
  margin-top: 2px;
  max-width: 100%;
  background-color: white; /* Set the background color */
  transition: box-shadow 0.3s, border-color 0.3s; /* Add transition for border color */
}

/* Apply border color when input is focused or has valid content */
input.style_input:focus,
input.style_input:valid,
select.style_input:focus {
  border-color: #2cda6d; /* Green border color */
}

/* Shadow effect when focused */
input.style_input:focus,
select.style_input:focus {
  box-shadow: 0 0 5px rgba(44, 218, 109, 0.5); /* Shadow effect when focused */
}

.container {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  min-height: 90vh;
  padding-top: 20px;
}
.registerForm {
  width: max-content;
  background-color: #ffffff;
  padding: 4vw;
  border-radius: 10px;
  margin: 32px auto;
}

b {
  font-size: 2rem;
}
.header {
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  padding: 10px 0;
}

.select_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  max-width: fit-content;
  border-radius: 28px;
  border: 2px solid #2cda6d;
  margin: 0 auto;
}

.selector {
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 500px;
  width: 80%;
  height: max-content;
  border: 1px solid blue;
  border-radius: 10px;
}
.patientSelector {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  color: white;
  background-color: #2cda6d;
  padding: 10px 32px 10px 36px; /*top right bottom left */
  border-radius: 28px;
  overflow: hidden;
}
.doctorSelector {
  padding: 0 10%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  color: #2cda6d;
  background-color: #ffffff;
  padding: 10px 36px 10px 32px;
  border-radius: 28px;
}
.patientSelector:hover,
.doctorSelector:hover {
  cursor: pointer;
}

.submitBtn {
  border: #2cda6d solid;
  background-color: #2cda6d;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
}


.mandatory {
  margin-top: 8px;
  font-size: 0.8rem;
  color: red;
}
@media screen and (max-width: 520px) {
  .row {
    font-size: 0.9rem;
  }
}
