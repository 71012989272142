body {
  margin: 0;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: Manrope, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f9fe;
}

code {
  font-family: Manrope;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
