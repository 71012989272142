.vitals-page{
    display: flex;
}
/* .vert-nav{
    height: 95vh;
    width: 120px;
    background-color: #FFFFFF ;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
} */
#vitals-dashboard{
    margin-left: 160px;
}
.page-label{
    font-size: 2rem;
    font-weight: bold;
    margin: 10px;
    display: flex;
    justify-content: space-between;
}
.vitalscontainer{
  margin-left: 160px;
}
.Label-vitals-ecg {
  width: 100%;
}

.page-label select{
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.vitals-table{
    display: flex;
    font-family: Kanit;
    /* border: solid orange; */
}
.row-vitals{
    display: flex;
    justify-content: left;
    /* border: solid red; */
    /* padding: 0px 10px; */
    
}
.col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border:solid red; */
    margin-right: 5px;

}
.Circle-vitals{
    background-color: #FFFFFF;
    width: 150px;
    height: 150px;
    padding: 5px 30px;
    box-shadow: 0px 4px 4px 0px #2cda6d;
    border-radius: 20px; 
    margin: 10px 20px 0px 20px;
    /* border: solid blue; */
    
}


.ecg-container {
  width: 100%;
}


.col-vitals {
  background-color: #FFFFFF;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  width: 107%;
  padding: 5px 30px;
  box-shadow: 0px 4px 4px 0px #80FF80;
  border-radius: 20px;
  margin: 10px 0px;
  margin-right: 10px;
  /* border: solid red; */
}
.progress{
    position: relative;
    /* margin-right: 10px; */
}

.progress-col{
    position: relative;
    /* border: solid pink; */
}

.Label-vitals-center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    height: 90px;
    width: 100px;
    font-size: 2rem;

  }

.Label-vitals {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40%;
    width: 100%; /* Adjust this if needed */
    font-size: 2rem;
    /* border: solid black; */
  }
  
#sys{
    background: linear-gradient(180deg, #A9FFC6 0%, #FFF 100%);
}

#dia{
    background: linear-gradient(180deg, #80FF80 0%, #FFF 100%);
}

.sidenav {
  height: 100%;
  width: 160px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  overflow-x: hidden;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#vitalsIcon{
    background-color: #2CDA6D;
    border-radius: 20px;
}
.sidenav div{
    padding: 20px;
    cursor: pointer;
}
.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
} 

.Label-vitals svg {
    width: 100%; /* Adjust the size of the SVG icon as needed */
  }

  .Label-vitals p {
    margin: 5px; /* Remove any default margin */
  }


  .Label-vitals > div {
    text-align: center; /* Center the text */
  }

  .Label-vitals svg{
    /* border: solid pink; */
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .body-temp{
    margin-bottom: 10px;
  }

  .Label-vitals-center > div {
    text-align: center; /* Center the text */
  }

  .Label-vitals-center svg {
    width: 80%; /* Adjust the size of the SVG icon as needed */
  }

  .timestamp{
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: larger;
    margin-right: -80px;
  }

  .blood-pressure{
    /* border: solid red; */
    /* background-color: #FFFFFF; */
    align-items: center;
    justify-content: center;
    padding: 5px 30px;
    /* box-shadow: 0px 4px 4px 0px #80FF80; */
    border-radius: 20px;
    /* margin: 10px 0px; */
    margin-right: 10px;
    margin-top: 2px;
  }

  .blood-pressure-heading{
    display: flex;
    justify-content: center;
  }

  

  .report-button {
    position: fixed;
    bottom: 9%; /* Adjust as needed */
    right: 5%; /* Adjust as needed */
    background-color: yellowgreen;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    z-index:  0;
    
  }


/* Add responsive styles for screens below 1024px */
/* Add responsive styles for screens below 1024px */
@media (max-width: 1024px) {
  #vitals-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0; /* Remove left margin */
    margin-right: 0; /* Remove right margin */
  }

  .vitalscontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0; /* Remove left margin */
  }

  .ecg-container {
    width: 100%;
    margin-left: 0; /* Remove left margin */
  }

  .vitals-table {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .row-vitals {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Add margin between rows for spacing */
    /* border: solid red; */
  }

  .col {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Ensure items wrap in small screens */
    /* border: solid red; */
  }

  .Circle-vitals {
    margin: 10px;
    width: 150px; /* Maintain original width */
    height: 150px; /* Maintain original height */
  }

  .Progress-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .Label-vitals-ecg {
    width: 100%;
  }

  .blood-pressure {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-left: -30px; */
  }

  .blood-pressure-heading {
    width: 100%;
    text-align: center;
  }
  
  .report-button {
    position: fixed;
    bottom: 0%; /* Adjust as needed */
    right: 45%; /* Adjust as needed */
    /* margin-top: 15px; */
    margin-bottom: 5px;
    background-color: yellowgreen;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    z-index: 0;
  }

  /* Center the date below the vitals dashboard */
 
  #vitals-dashboard{
    margin-left: 0;
  }
 
  .timestamp{
    display: flex;
    flex-direction: column;
  }
}
