.sidenav {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  padding-left: 4px;
}



#appointmentIcon {
  background-color: #2cda6d;
  border-radius: 20px;
}
.sidenav div {
  padding: 20px;
  cursor: pointer;
}
.sidenav a {
  padding: 6px 8px 6px 6px;
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
}

.sidenav a:hover {
  color: #2cda6d;
}
#appointment {
  margin-left: 300px;
  margin-top: 60px;
  align-content: center;

}
.page-label {
  font-size: 2rem;
  font-weight: bold;
  margin: 10px;
  display: flex;
  justify-content: space-between;
}


.appointmentContainer {
  width: 80%;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 25px;

}

.inputDiv {
  width: 100%;
  padding: 10px 0;
}

.inputSelect {
  width: 100%;
}
.btnDoctorSearch {
  height: max-content;
  width: max-content;
  padding: 10px;
  color: #ffffff;
  background-color: #2cda6d;
  cursor: pointer;
  border-radius: 10px;
}

li {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: max-content;
  margin-right: 20px;
}
li::marker {
  color: transparent;
}

.submitBtn {
  border: #2cda6d solid;
  background-color: #2cda6d;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
}

.style_input {
  padding: 10px;
  border: 1px solid #2cda6d; /* Default border color */
  border-radius: 10px;
  margin-top: 2px;
  max-width: 100%;
  background-color: white; /* Set the background color */
  transition: box-shadow 0.3s, border-color 0.3s; /* Add transition for border color */
  cursor: pointer;
}

.overlayAppoint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  z-index: 999; /* Ensure the overlay is behind the modal but in front of the appointment page */
}




@media screen and (max-width: 960px) {
 
  #appointment {
    margin-left: 0;
    margin-top: 0;
  }

  .appointmentContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .doctor-list{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
}