.style_input {
  padding: 10px;
  border: 1px solid #2cda6d; /* Default border color */
  border-radius: 10px;
  margin-top: 2px;
  max-width: 100%;
  background-color: white; /* Set the background color */
  transition: box-shadow 0.3s, border-color 0.3s; /* Add transition for border color */
  cursor: pointer;
}

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure the modal is on top of other content */
  /* Add any other styling properties as needed */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  z-index: 999; /* Ensure the overlay is behind the modal but in front of the appointment page */
}
