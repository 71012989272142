.overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    background-color: rgba(164, 164, 164, 0.732);
}
.modal{
    display: flex;
    justify-content: center;
    
}
.modal-background{
    min-width: 270px;
    position: absolute;
    top: 10%;
    z-index: 2;
    background-color: white;
    border-radius: 10px;
    padding: 2% 3%;
    box-shadow: 5px 5px 10px #5d5d5d;
    
}
.modalRow{
    margin: 10px 0;
    min-width: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    
  }
.modalRow input{
    border: transparent;
    /* border-bottom: 1px solid rgb(163, 163, 163); */
    min-width: 270px;
    margin-bottom: 15px;
    border: solid #2CDA6D;
    padding: 8px;
    border-radius: 10px;

}

.modalRow .input-field-name{
    margin-bottom: 4px;
}
.signinText{
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: left;
}
.modalContainer{
    position: relative;

}
.closeModal{
    position: absolute;
    right: -9%;
    top:-4%;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent grey background */
    z-index: 2; /* Ensure the overlay is behind the modal but in front of the appointment page */
  }
  
.closeModal button{
    border: transparent;
    color: rgb(0, 0, 0);
    background-color: #D5DEED;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 40px;
    width: 40px;
}
.modalPatientSelector {
    width: 50%;
    text-align: center;
    background-color: #2CDA6D;
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px; /* Add border radius */
    border-radius: 10px; /* Add border radius */
    border: 1px solid #2CDA6D;
}

.modalDoctorSelector {
    width: 50%;
    text-align: center;
    background-color: #ffffff;
    color: #2CDA6D;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px; /* Add border radius */
    border-radius: 10px; /* Add border radius */
    border: 1px solid #2CDA6D;
}

.loginBtn{
    background-color: #2CDA6D;
    color: white;
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 3px;
    /* margin-bottom: 15px; */

}


.modalRow .forgotPassword {
    margin-top: 15px; /* Add space at the top */
    color: #2CDA6D;
    font-size: 14px;
    cursor: pointer;
  }
  

.modalRow .googleSignupBtn {
    background-color: #b0e7c3;
    color: #0c0730;
    border: 2px solid #2CDA6D;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .googleSignupBtn img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }


  .signupText {
    font-size: 14px;
    text-align: center;
    margin-top: 15px; /* Add space between the "Sign up with Google" button and the text */
}

.signupLink {
    color: #2CDA6D; /* Change color to match your design */
    cursor: pointer;
}

.signupLink:hover {
    text-decoration: underline; /* Add underline effect on hover */
}

@media screen and (max-width: 900px) {
    .closeModal{
        right: -7%;
        top: -7%;
    }
  }
  @media screen and (max-width: 600px) {
    .closeModal{
        transform: scale(0.8);
        right: -6%;
        top: -7%;
    }
  }