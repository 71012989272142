.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  padding-left: 18px;
}

#dashboardIcon {
  background-color: #2cda6d;
  border-radius: 20px;
  margin-top: 32px;
}
.sidenav div {
  padding: 20px;
  cursor: pointer;
}

.sidenav a:hover {
  color: #2cda6d;
}

.sidenav a:active {
  color: #2cda6d;
}

.sidenav.hide {
  left: -250px;
}

.hamburgerIcon {
  /* Your styles for the hamburger icon */
  display: none;
  background-color: transparent;
  border: none;
  padding: 10px; /* Adjust padding as needed */
  cursor: pointer;
}

/* Optionally, style the image inside the hamburger icon */
.hamburgerIcon img {
  width: 30px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
}

.closeButton{
  display: none;
}

@media only screen and (max-width: 1024px) {
  .sidenav {
    width: 200px; /* Adjust width for smaller screens */
    /* background-color: #2cda6d; */

    height:fit-content;
    
    overflow-x: hidden;
    transition: 0.5s;
  }

  .closeButton {
    /* Your close button styles */
    display: inline;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    z-index: 100;
  }
  
  /* Hide the close button when sidenav is not open */
  .sidenav:not(.show) .closeButton {
    display: none;
  }


  .hamburgerIcon{
    display: inline;
  }
}
