.sidenav {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  padding-left: 4px;
}
#dashboardIcon {
  background-color: #2cda6d;
  border-radius: 20px;
  margin-top: 32px;
}
.sidenav div {
  padding: 20px;
  cursor: pointer;
}
.sidenav a {
  padding: 6px 8px 6px 6px;
  text-decoration: none;
  font-size: 25px;
  color: #000000;
  display: block;
}

.sidenav a:hover {
  color: #2cda6d;
}

.sidenav a:active {
  color: #2cda6d;
}
.page-label-1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
#dashboard {
  margin-left: 300px;
  margin-top: 60px;
}
.patientDetailContainer {
  /* width: 90%; */
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 32px 48px 32px 30px;
}
.profileBanner {
  display: flex;
  justify-content: space-around;
}
/* #profilePhoto{
  width: 100%;
} */
.pateintDetails {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  flex-wrap: wrap;
  border-radius: 10px;
  padding: 4px;
  width: 40%;
  /* border: solid blue; */
  
}

.label {
  display: flex;
  margin-left: 16px;
  margin-top: 12px;
  justify-content: left;
}
.value {
  margin-left: 8px;
}

.tableContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 50px 25px;
  /* border: solid gray; */
}

#tableDiv {
  width: 100%; /* Changed from 90% to 100% */
  /* border-radius: 20px; */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1px;
  overflow-x: auto; /* Added for horizontal scrolling on small screens */
  /* border: solid red; */
}

#table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

#table th,
#table td {
  border-bottom: 1px solid #d9dbdc;
  padding: 8px;
  text-align: center;
}

#table th {
  font-weight: bold;
  background-color: #f5f9fe;
}

#table tbody tr:hover {
  background-color: #f5f5f5;
}

td button {
  border: transparent;
  background-color: #2cda6d;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
}

/* Responsive Design */
@media (min-width: 640px) {
  #table th,
  #table td {
    padding: 10px;
  }
}

@media (min-width: 768px) {
  .tableContainer {
    margin: 50px 50px;
  }
}

@media (min-width: 960px) {
  #table th,
  #table td {
    padding: 12px;
  }
}

@media (min-width: 1280px) {
  #table th,
  #table td {
    padding: 15px;
  }
}

@media (min-width: 1536px) {
  #table th,
  #table td {
    padding: 20px;
  }
}

/* Hide Doctor, Diagnosis, and Prescription columns on screens less than 960px */
@media (max-width: 959px) {
  #table th:nth-child(2),
  #table td:nth-child(2),
  #table th:nth-child(3),
  #table td:nth-child(3),
  #table th:nth-child(4),
  #table td:nth-child(4) {
    display: none;
  }
}


div > h3:active {
  color: #2cda6d;
}

.overlayAppoint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  z-index: 999; /* Ensure the overlay is behind the modal but in front of the appointment page */
}


/* Add this media query at the bottom of your CSS file */
@media screen and (max-width: 1024px) {
   
  .page-label {
    font-size: 1rem;
    text-align: center;
    width: 100%;
    margin-left: 10px;
  }


  #dashboard {
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-top: 0;
    font-size: small;
  }

  .patientDetailContainer{
    width: 100%;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }

  .profile-img{
    display: none;
  }

  #profilePhoto{
    display: none;
  }


  .pateintDetails{
    width: 100%;
    height: 150px;
    margin-top: 10px;
    align-items: center;
  }

  .tableContainer{
    width: 100%;
    height: 150px;
  }

  #table{
    width: 105%;
    height: 100%;
    margin-left: -9px;
    /* display: flex; */
    /* align-self: center; */
    /* align-items: center; */
    justify-content: center;
  }

  #tableDiv{
    width: fit-content;
    height: 100%;
    padding: 10px;
  }

}