.page-label {
  font-size: 2rem;
  font-weight: bold;
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

#dashboard {
  margin-left: 250px;
  margin-top: 72px;
  /* border: solid gray; */
}

.patientDetailContainer {
  width: 90%;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 32px 48px 32px 30px;
  /* border: solid green; */
}
.profileBanner {
  display: flex;
  justify-content: space-around;
}
/* #profilePhoto{
  width: 100%;
} */
.pateintDetails {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  flex-wrap: wrap;
  border-radius: 10px;
  padding: 4px;
  width: 40%;
  /* border: solid blue; */

}

.label {
  display: flex;
  margin-left: 16px;
  margin-top: 12px;
  justify-content: left;
  /* border: solid red; */

}
.value {
  margin-left: 8px;
}

.tableContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 50px 25px;
}
#tableDiv {
  width: 90%;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

#table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

#table th {
  border-bottom: 1px solid #f5f9fe;
  padding: 8px;
  text-align: center;
  font-weight: bold;
  background-color: #f5f9fe;
}
#table td {
  border-bottom: 1px solid #d9dbdc;
  padding: 8px;
  text-align: center;
}

#table th {
  background-color: #f5f9fe;
}

/* Optional: Hover effect on table rows */
#table tbody tr:hover {
  background-color: #f5f5f5;
}

/* Optional: Add some spacing between cells for better readability */
#table td {
  padding: 10px;
}

td button {
  border: transparent;
  background-color: #2cda6d;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
}

div > h3:active {
  color: #2cda6d;
}

@media (max-width: 1285px) {
  #dashboard {
    margin-left: 0;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page-label {
    justify-content: center;
    text-align: center;
  }

  .patientDetailContainer {
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profileBanner {
    display: none;
  }

  .pateintDetails {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
    max-width: fit-content;
    height: auto;
    padding: 16px;
    align-self: left;
  }

  .label {
    margin-left: 0;
    justify-content: center;
  }

  .tableContainer {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #tableDiv {
    width: 100%;
    padding: 10px;
  }

  #table {
    font-size: 0.8rem;
  }

  #table th, #table td {
    padding: 5px;
  }

  #profilePhoto {
    display: none;
  }

  .profile-img {
    display: none;
  }
}
